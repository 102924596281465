/* 飞天云动-解决方案-职业培训 */
<template>
  <div>
    <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
            src="https://h5.ophyer.cn/official_website/banner/LavOccupation.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">职业培训</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                    XR + 教育教学，推进现代信息技术融入实验教学
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img  src="https://h5.ophyer.cn/official_website/banner/LavOccupation_01.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <v-container style="max-width:1200px">
      <v-card class="warp warp2" :flat="true">
        <div class="public-title" style="margin:80px 0 20px 0">
          <!-- <p>SERVICE INTRODUCTION</p> -->
          <div>
            <span>{{data.module.title}}</span>
            <span>{{data.module.subtitle}}</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered  class="g-tabs">
          <v-tab v-for="(item,index) in data.module.itemList" :key="index">
            <v-card-title class="font-size-20" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.title }}</v-card-title>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item,index) in data.module.itemList" :key="index" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <v-img v-show="tab === index" :src="item.src" width="400" height="255" class="rounded-xl">
                    <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                size="80"
                                width="8"
                                color="grey lighten-3"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                  </v-img>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="right px-5" v-show="tab === index">
                    <v-card-title class="title px-0 py-1" v-text="item.title" />
                    <v-card-text class="tip" v-text="item.content" />
                  </div>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-container style="padding-left: 5%;padding-right: 5%;">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:80px">
            <!-- <p>IMPROVE TEACHING QUALITY</p> -->
            <div>
                <span>{{data.module1.title}}</span>
                <!-- <span>{{data.module1.subtitle}}</span> -->
            </div>
        </div>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="9">
              <v-row>
                <v-col cols="4"  v-for="(card,index) in data.module1.itemList" :key="index">
                    <v-card elevation="0">
                      <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="120" height="120">
                        <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template></v-img></div>
                      <v-card-title class="font-size-18 font-weight-bold justify-center text-align-center" v-text="card.title"></v-card-title>
                      <v-card-text class="font-size-14 text-align-center"  style="color:#666" v-text="card.content"></v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container style="padding-bottom: 60px;">
      <div class="public-title" style="margin:34px 0 46px 0">
        <!-- <p>STANDARD PRODUCT</p> -->
        <div>
          <span>{{data.module2.title}}</span>
          <!-- <span>{{data.module2.subtitle}}</span> -->
        </div>
      </div>
      <v-container>
        <div class="d-flex justify-center align-center">
            <v-img
              class="flex-grow-0 rounded-xl"
              height="255"
              width="400"
              :src="data.module2.src"
            >
            <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template>
            </v-img>
            <div style="margin-left:83px">
              <v-card-title class="font-size-24 font-weight-bold py-0 px-0">{{data.module2.imgTitle}}</v-card-title>
              <v-card-text class="mt-5 px-0 font-size-14" style="white-space:pre-wrap;color: #666666;">{{data.module2.imgText}}</v-card-text>
            </div>
        </div>
      </v-container>
    </v-container>
    <v-container>
      <div class="public-title" style="margin-top:70px;margin-bottom:-10px">
          <!-- <p>SOLUTIONS</p> -->
          <div>
            <span>{{data.module3.title}}</span>
            <!-- <span>{{data.module3.subtitle}}</span> -->
          </div>
      </div>
      <v-row class="mt-5 max-width-1200" style="margin:0 auto">
        <v-col cols="4" v-for="(card,index) in data.module3.itemList" :key="index" class="d-flex child-flex">
            <div class="box-img rounded-xl" :style="{backgroundImage: 'url('+card.src+')'}">
              <div>
                  <span>{{ card.title }}</span>
                  <span>{{ card.content }}</span>
                </div>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
let _this
import lavData from '@/static/lavData.js'
export default {
  name: "lavOccupation",
  data: () => ({
    imgLoad: false,
    data:lavData.lavOccupation,
    tab: null,
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
}
};
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.warp2 {
  .box {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 80px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-right: 110px;
    .v-card__text {
      padding: 0;
      line-height: 30px!important;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
      color:#666!important;
      opacity:1;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.box-img {
    width: 300px;
    height: 210px;
    background-size: cover;
    overflow: hidden;
    transition: all 0.3s ease-in;
    div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
            &:nth-child(1){
                font-weight: bold;
            }
            &:nth-child(2){
                font-size: 14px;
                margin-top:7px;
                text-align: center;
                padding: 5px 15px;
                line-height: 20px;
            }
        }
    }
    &:hover {
    transform: scale(1.1);
    }
}
</style>